.campaignAddBox {
  .campaignPreview {
    background-color: #fff;
    .left {
      .OfferValueBox {
        .innerBox {
          padding: 25px 20px;
          border-radius: @border-radius-box;
          color: #fff;
          font-size: 1.2rem;
          .shadow;
          h5 {
            color: #fff;
          }
          p {
            font-size: 1.4rem;
          }
          &.denominationBox {
            background-color: @orange-color;
          }
          &.voucherBox {
            background-color: @primary-color;
          }
          &.valueBox {
            background-color: @aqua-color;
          }
        }
      }
      .campaignInformationCard{
        .ant-input-disabled, .ant-input[disabled],.ant-picker.ant-picker-disabled,.ant-picker-input>input[disabled]{
           background-color: #fff!important;
           font-weight: 600;
        }
      }
      .productsBox {
        .ant-empty{
          height: 300px!important;
        }
        .heading {
          color: @sidebar-menu-dark;
          margin-bottom: 20px;
        }
        .innerBox {
          border-radius: 4px;
          margin-bottom: 3rem;
          overflow: hidden;
          .shadow;
          img {
            width: 100%;
            height: 150px;
            object-fit: contain;
            border-radius: 4px 4px 0 0;
            margin-bottom: 0px;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out; 
            &:hover{
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }          
          }
         
          .details {
            padding: 10px;
            color: @sidebar-menu-dark;
            p {
              margin-bottom: 0px;
              font-weight: 500;
              span {
                color: @primary-color;
              }
            }
          }
        }
      }
    }
    .right {
      .stateBox {
        .heading {
          margin-bottom: 10px;
          display: block;
          color: @sidebar-menu-dark;
          font-weight: 600;
        }
        border-radius: @border-radius-box;
        border: 1px solid #ddd;
        padding: 20px;
        .shadow-sm;
        .inner {
          min-height: 50px;
          height: 155px;
          overflow-y: auto;
          overflow-x: hidden;
          p {
            &:last-child {
              margin-bottom: 0px;
            }
          }
          .ant-empty{
            height: 100%!important;
            .ant-empty-image{
              height: 90px!important;
              margin-bottom: 0px;
            }
          }
        }
      }
      .templateBox {
        display: flex;
        .heading {
          margin-bottom: 10px;
          display: block;
          color: @sidebar-menu-dark;
          font-weight: 600;
        }
        .previewBox {
          border-radius: @border-radius-box;
          img {
            width: 100%;
            height: 234px;
            object-fit: contain;
            padding: 5px;
            border-radius: @border-radius-box;
            .shadow-sm;
          }
        }
      }
    }
    .buttonBox {
      display: flex;
      justify-content: flex-end;
      padding: 0px!important;
      button{
        height: 50px;
        padding-left:  40px;
        padding-right:  40px;
      }
      .btnDraft {
        background-color: @aqua-color!important;
        border-color: @aqua-color!important;
      }
    }
    .readMoreText{
      color: @orange-color;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
