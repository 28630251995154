@primary-color:#425EE2; // primary color for all components
@secondary-color: #0D0D0D; // primary color for all components
@link-color: #425EE2; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-radius-box: 6px;
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05) ;// major shadow for layers
@text-color-light: #fff; // white state color
@text-color-dark: #000; // black state color
@bg-color-light-gray:#F5F5F5;
@bg-color-ligher-gray:#F3F3FF;
@bg-color-error-light:#FFDADB;
@bg-row-highlightcolor:#fafafa;
@bg-danger: #fff5f8;
@text-danger: #ff4d4f;
@bg-warning: #fff8e1;
@text-warning: #faad14;
@bg-success:#E8FFF3;
@text-success: #50CD89;
@bg-info:#f1faff;
@text-info: #00a3ff;
@primary-color-hover:#5e76e5;
@sidebar-menu-light:#F3F3FF;
@sidebar-menu-dark: #192E5A;
@menu-text-light: #9BA8AA;
@orange-color: #FB984A;
@aqua-color: #3BD8A8;
@aqua-light-color: #e1f6fa;
@box-gray-color: #f8f9fb;
@heading-light-color: #7f8c9c;
@ekikart-primary-color: #30649B;
@f-primary-color: #2232E1;
@f-secondary-color: #FF8911;
@f-ternary-color: #29C149;
@f-blue-color: #001847;
@f-gray-color:#F5F6FE;
/*****Common*****/

/*Scrollbar*/
.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
 
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
 
.scrollbar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acacac;
}

/*Width*/
.w-100{
  width: 100%;
}

/*Color*/
.text-dark{
  color:@heading-color;
}
.text-color-secondary{
  color: @text-color-secondary;
}
.text-color-priamry{
  color: @primary-color!important;
}
.btnBlue{
  background-color: @sidebar-menu-dark!important;
  border-color: @sidebar-menu-dark!important;
}
.btnOrange{
  background-color: @orange-color!important;
  border-color: @orange-color!important;
}
/*Display*/
.d-none{
  display: none;
}
.d-block{
  display: block!important;
}
.d-flex{
  display: flex;
}

/*Flex*/
.align-item-center{
  align-items: center;
}
.align-self-start {
  align-self: flex-start!important;
}
.align-self-center{
  align-self: center!important;
}
.align-self-end {
  align-self: flex-end!important;
}
.justify-content-start{
  justify-content: flex-start!important;
}
.justify-content-between{
  justify-content: space-between!important;
}
.justify-content-end{
  justify-content: flex-end!important;
}
.flex-row{
  flex-direction: row!important;
}
.flex-column{
  flex-direction: column!important;
}
.flexWrap{
  flex-wrap: wrap;
}
/*Background*/
.bg-white {
  background: #fff;
}
.bg-primary{
  background-color: @primary-color!important;
}
.bg-light-gray{
    background-color: @bg-color-light-gray;
}

/*Margin*/
.m-0{
  margin: 0px;
}
.m-10{
  margin: 10px!important;
}
.mt-10{
  margin-top:10px
}
.mt-auto{
  margin-top: auto;
}
.mb-10{
  margin-bottom:10px
}
.mLR10{
  margin: 0px 10px;
}
.mt-1{
  margin-top: .25rem;
}
.mt-2{
  margin-top: .50rem;
}
.mt-3{
  margin-top: .75rem;
}
.mt-4{
  margin-top: 1rem;
}
.mt-5{
  margin-top: 1.25rem;
}
.ml-1{
  margin-left: .25rem;
}
.ml-2{
  margin-left: .50rem;
}
.ml-3{
  margin-left: .75rem;
}
.ml-4{
  margin-left: 1rem;
}
.ml-5{
  margin-left: 1.25rem;
}
.ml-auto{
  margin-left: auto;
}
.mb-0{
  margin-bottom: 0px!important;
}
.mb-1{
  margin-bottom: 0.25rem!important;
}
.mb-2{
  margin-bottom: 0.50rem!important;
}
.mb-3{
  margin-bottom: 0.75rem!important;
}
.mb-4{
  margin-bottom: 1rem!important;
}
.mb-5{
  margin-bottom: 1.25rem!important;
}
.mr-1{
  margin-right: .25rem;
}
.mr-2{
  margin-right: .50rem;
}
.mr-3{
  margin-right: .75rem;
}
.mr-4{
  margin-right: 1rem;
}
/*Padding*/
.p-0{
  padding: 0rem!important;
}
.p-1{
  padding: 0.25rem;
}
.p-2{
  padding: .50rem;
}
.p-3{
  padding: .75rem;
}
.p-4{
  padding: .1rem;
}
.p-5{
  padding: 1.25rem!important;
}
.pl-0{
  padding-left: 0px!important;
}
.pr-0{
  padding-right: 0px!important;
}
.pl-1{
  padding-left: 0.25rem!important;
}
.pl-2{
  padding-left: 0.50rem!important;
}
.pl-3{
  padding-left: 0.75rem;
}
.pl-4{
  padding-left: 0.1rem;
}
.pr-1{
  padding-right: 0.25rem!important;
}
.pr-2{
  padding-right: 0.50rem!important;
}
.pr-3{
  padding-right: 0.75rem;
}
.pr-4{
  padding-right: 0.1rem;
}
.pLR10{
  padding: 0px 10px;
}
.p-x6-y16{
  padding: 6px 16px;
}
.p-x8-y16{
  padding: 8px 16px;
}
.p-x16-y0{
  padding: 08px 16px;
}
.pt-1{
  padding-top: 0.25rem!important;
}
.pt-2{
  padding-top: 0.50rem!important;
}
.pt-3{
  padding-top: 0.75rem;
}
.pt-4{
  padding-top: 0.1rem;
}
/*Float Alignment*/
.float-left{
  float: left
}
.float-right{
  float: right
}

/*Text Alignment*/
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-uppercase{
  text-transform: uppercase;
}

/*Font Size*/
.fs-07{
  font-size: .7rem;
}
.fs-08{
  font-size: .8rem;
}
.fs-09{
  font-size: .9rem;
}
.fs-1{
  font-size: 1rem;
}
.fs-12{
  font-size: 1.2rem;
}
.fs-15{
  font-size: 1.5rem;
}
.fs-2{
  font-size: 2rem;
}

/*Font Wight*/
.fw-normal{
  font-weight: normal!important
}
.fw-500{
  font-weight: 500!important
}
.fw-600{
  font-weight: 600!important
}
.fw-700{
  font-weight: 700!important
}
.br-b-0{
  border-bottom:0px
}

/*Border*/
.br-r-0{
  border-right:0px
}

/*Width & height*/
.image40{
  width: 40px;
  height: 40px;
}
.image50{
  width: 50px;
  height: 50px;
}
.image60{
  width: 60px;
  height: 60px;
}
.image70{
  width: 70px;
  height: 70px;
}
.object-contain{
  object-fit:contain;
}
/*Ellipse*/
.text-ellipse1{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Popinter*/
.cursor-pointer{
  cursor: pointer;
}

/*Badge*/
.badge-sm{
  padding:5px 10px;
  border-radius:6px;
  font-weight:500;
  font-size:12px;
}

/*Shadow*/
.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}


/****Common*****/

#wrapper{
  max-height: 100%;
  overflow-y: scroll;
}
.anticon svg, output {
  vertical-align: unset!important;
}
body.fixed-left-void {
  min-height: 100%!important;
  overflow: hidden!important;
}

/*Heading - Left side title and right side button*/
.left-heading-right-button{
   display: flex;
   margin-bottom: 10px;
   h4{
     flex-grow: 1;
   }
   .space-ml-8{
     margin-left: 8px;
   }
}

/*Input Controls*/
.ant-picker.ant-picker-focused,.ant-picker-focused, .ant-picker:hover,.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus,.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,.ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: @primary-color;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #cfd7ff!important;
}
.ant-picker-focused,.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
box-shadow: none!important;
}


