@import  "../../../assets/css/common.less";
/*Sidebar*/
#kardstashLayout{
    .trigger {
      padding: 6px 6px;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      position: fixed;
      z-index: 100000;
      background-color: #D5DCFB;
      color: @primary-color;
      margin-left: -12px;
      border-radius: 50%;
      margin-top: 20px;
      &:hover{
        background-color: @primary-color;
        color: #fff;
      }
    }
    .logo {
      border-bottom:2px solid @sidebar-menu-light;      
      height: 69.5px;
    }
    .kardstashSidebar{
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 58px;
      height: 100%;
      z-index: 100;
      &.ant-layout-sider-collapsed ~ .ant-layout.site-layout{
        padding-left: 138px;
      }
      &.ant-layout-sider-collapsed + .ant-layout.site-layout .headerFix{
        width: calc(100% - 138px)!important;
      }
      .ant-menu{
        background-color: #fff;
        .ant-menu-item{
          padding: 0 30px!important;
          &:hover{
            background-color: @bg-color-ligher-gray;
          }
          span,a{
            color:@sidebar-menu-dark;
          }
          .ant-menu-item-icon{
            color:@menu-text-light!important;
          }
          &.ant-menu-item-selected{
            background-color: @bg-color-ligher-gray;
            .ant-menu-item-icon{
                color:@sidebar-menu-dark!important;
            }
            .ant-menu-title-content{
              span,a{
                color:@sidebar-menu-dark;
                font-weight: 500;
              }
            }  
            &::after{
              border-color:@orange-color;
            }          
          }
          .anticon{
            font-size: 14px!important;
          }
        }
      }
    }
    .ant-layout-sider-collapsed{
      /*&:hover{
        transition: width .5s ease-in!important;
        width: 230px!important;
        max-width: 230px!important;
        flex: 0 0 230px!important;
        position: absolute!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        z-index: 98!important;
        overflow: hidden!important;
        .ant-menu-item{
          padding: 0 calc(15% - 16px/2);
        }
        .anticon{
          margin-right: 10px;
          font-size: 14px;
        }
        .anticon ~ span{
          opacity:1
        }
      }*/
      .logoBig{
        .d-none()!important;
      }
      .logoSmall{
        .d-block();
        padding: 5px;
        object-fit: contain;
      }        
    } 
    .logoSmall{
      .d-none()!important;
    }
}

@hack: true;@import "/Users/nagarajanchandramohan/Desktop/TheeCode/SpacePointe/Ekikart/karstash-react/src/theme/light-theme.less";