.list {
  .joinFilterSec {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-input-affix-wrapper,
    .ant-select-selector {
      height: 44px;
      border-radius: 8px;
    }
    .statusDropdown{
      .ant-select-selection-placeholder,.ant-select-selection-item{
        display: flex;
        align-items: center;
      }
    }
    .notificationIconSec{
      display: flex;
      height: 82%;
      align-items: center;
      justify-content: flex-end;
      .anticon.anticon-bell{
          font-size: 1.5rem;
          color: rgb(191, 191, 191);
      }
      .ant-badge-dot{
        top: 15px;
        right: 3px;
      }
      
    }
  }
  .campaignListSec {
    background-color: transparent;
    background-image: url("../../assets/images/Join/WhiteBg.png");
    text-align: center;
    border-radius: 10px;
    border: 0px;
    .ant-card-body {
      padding: 0px;
    }
    header.innerBox {
      padding: 20px 10px;
      border-bottom: 3px dashed @ekikart-primary-color;
      &:hover {
        border-bottom: 3px dashed @orange-color;
        h3 {
          color: @orange-color;
        }
      }
      h3 {
        margin-bottom: 15px;
        font-size: 1.2rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 28px;
      }
      span {
        color: @ekikart-primary-color;
        border: 2px solid @ekikart-primary-color;
        border-radius: 6px;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 1px 20px;
      }
      img {
        display: flex;
        justify-content: center;
        margin: 20px auto 0 auto;
        height: 70px;
        width: 100%;
        object-fit: contain;
      }
      .joinedWrapper {
        width: 85px;
        height: 88px;
        overflow: hidden;
        position: absolute;
        top: -3px;
        right: -3px;
        .ribbon {
          font-size: 11px;
          color: #fff;
          text-align: center;
          text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          position: relative;
          padding: 3px 0;
          left: -5px;
          top: 15px;
          width: 120px;
          background-color: @ekikart-primary-color;
          color: #fff;
          -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          height: 23px;
          &:before,
          &:after {
            content: "";
            border-top: 3px solid @ekikart-primary-color;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            position: absolute;
            bottom: -3px;
          }
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
      }

      .statusPoint {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: 15px;
        &.Published {
          background-color: @orange-color;
        }
        &.Active {
          background-color: @text-success;
        }
        &.Expired {
          background-color: @text-danger;
        }
        &.Blocked {
          background-color: @heading-light-color;
        }
      }
    }
    footer {
      background-color: @ekikart-primary-color;
      padding: 15px;
      border-radius: 10px;
      p {
        margin-bottom: 0px !important;
        color: #fff;
        text-transform: uppercase;
        strong {
          font-weight: 500 !important;
        }
      }
    }
    .halfCircleLeft {
      width: 20px;
      height: 40px;
      background-color: #f0f2f5;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
      border-left: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      left: 0px;
      bottom: 38px;
    }
    .halfCircleRight {
      width: 20px;
      height: 40px;
      background-color: #f0f2f5;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border-left: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      right: 0px;
      bottom: 38px;
    }
  }
}
