@import  "../../../assets/css/common.less";
.profile-sider{
    background-color: @primary-color!important;
    text-align: center!important;
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start!important;
    flex-direction: column!important;
    position: fixed;
    top: 0;
    left:0;
    height: 100%;
    z-index: 100;
    width:58px;
    li{
        height: 50px;
        line-height: 50px;
        width: 100%;
        font-size: 1rem;
        a{
            color:@text-color-light;
            font-size: 1rem;
        }
        &.active{
            background-color: hsla(0,0%,100%,.08)!important;
        }
    }
    .avtar{
        height: 69px;
        width: 100%;
        img{
            object-fit: contain;
            padding: 12px;
        }
    }
}
@hack: true;@import "/Users/nagarajanchandramohan/Desktop/TheeCode/SpacePointe/Ekikart/karstash-react/src/theme/light-theme.less";