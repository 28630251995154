.cTemplateSec{
    .cTemplatePreview{
        display: flex;
        justify-content: center;
        position: relative;
        img{
            position: relative;
            width: 400px;
            height: 400px;
            object-fit: contain;
            // padding: 10px;
            border: 2px dashed #ddd;
            margin-bottom: 5px;
        }
        .box{
            // text-align: center;
            button{
                border:0px;
                font-weight: 600;    
                padding:10px 20px;
                cursor: move;           
            }
        }
        .cTemplateButtonSec{
            border: 0px;
            .btnFooter{
                text-align: center;
                padding-top: 10px;
                button:first-child{
                    background-color: @aqua-color!important;
                    border-color: @aqua-color!important;
                }                
            }
        }
    }
}
.templateUploadSec{
    padding: 1rem!important;
    border: 2px solid #eee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: default;
    .ant-upload-list-picture-card-container{
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
        width: 100%;
        height: 200px;
        margin: auto;
        border: 2px dashed #425EE2!important;
        .dragDropText{
            color: #425EE2;
            span{
                font-size: 3rem;
            }
        }
    }
    .customTemplateBox {
        background-color: @box-gray-color;
        border-radius: @border-radius-box;
        // margin-bottom: 40px;
        color: @primary-color;
        display: flex;
        height: 100%;
        width: 100%;
        .innerBox {
          background-color: #fff;
          padding: 20px;
          border-radius: @border-radius-box;
          width: 100%;
          display: flex;
          .shadow-sm;
          label {
            justify-content: center;
            display: flex;
            margin: auto;
            cursor: pointer;
            width: 100% !important;
            .ant-upload-select-picture-card {
              background: #fff;
              border: 3px dashed #ddd;
              margin-bottom: 0px;
              border-radius: @border-radius-box;
              height: 160px;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0.5rem;
              .anticon-cloud-upload {
                color: @primary-color;
                font-size: 3.8rem;
                margin-bottom: 6px;
              }
              h5 {
                color: @primary-color;
                font-size: 0.8rem;
                font-weight: 600;
              }
            }
            img {
              width: 100%;
              margin: auto;
              height: 100%;
              object-fit: contain;
            }
  
            .ant-upload-list-picture-card-container {
              margin-bottom: 0px;
              height: 300px;
            }
  
            .ant-upload.ant-upload-select-picture-card > .ant-upload {
              color: rgb(196, 189, 189);
            }
            .ant-upload.ant-upload-select-picture-card,
            .ant-upload-list-picture-card-container {
              width: 100% !important;
            }
          }
          .dragDropText {
            p {
              color: @primary-color;
            }
          }
        }
       
      }
      .buttonSelectionSec{
        p{
          font-weight: 500;
        }
        .colorPicker{
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-align: stretch;
          align-items: stretch;
          width: 100%;
          p{
            font-weight: 500;
          }
          .p-form-control{
            flex: 1 1 auto;
            width: 1%;
            padding: 8px 11px;
            color: rgba(0,0,0,.85);
            font-size: 14px;
            line-height: 1.5715;
            background: #EAECEB!important;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 4px 0px 0px 4px;
            transition: all 0.3s;
          }
          .c-form-control{
            height: 100%;
            background: #EAECEB!important;
            border: 1px solid #d9d9d9;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
}
