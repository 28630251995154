@media only screen and (min-width: 1000px) {
    #mobile_body {
        background: #555;
        background-image: url('../../assets/images/linen.png');
        flex: 1;
        #mobile_page {
            -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 40%);
            -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
            -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 15px rgb(0 0 0 / 40%);
            margin: 50px auto;
            width: 320px;
            // padding: 10px;
            // border-radius: 10px;
        }
    }
}
@media only screen and (max-width: 991px)  {
    #mobile_body {
        background: #555;
        background-image: url('../../assets/images/linen.png');
        flex: 1;
        #mobile_page {
            width: 320px;
            padding: 10px;
            margin: 10px auto;
            border-radius: 10px;
        }
    }
}

#mobile_body {
    .campaign-condition {
        padding: 15px;
        h3 {
            font-size: 13px;
        }
        p {
            font-size: 10px;
            word-break: break-word;
        }
        .readMoreText{
            color:#425EE2;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .text-center {
        .campaignCodeSec{
            background-color: #3BD8A8;
            display: inline-block;
            padding: 10px 20px;
            border-radius: 6px;
            margin-bottom: 20px!important;
            color: #fff;
        }
        
        .expireText{
            color: red;
        }
        .barcodeBox{
            background-color: #fff;
            border: 1px dashed #999;
            border-radius: 10px;
            width: 91%;
            margin: auto;
        }
        svg{
            width: 100% !important;
        }
        button{
            // width:185px;
            height: 42px;
            font-size: 1rem;
            &.btnBlue{
                background-color: @aqua-color!important;
                border-color: @aqua-color!important;
            }
            &.btnOrange{
                background-color: @orange-color!important;
                border-color: @orange-color!important;
            }
        }
    }
    .campaignDetailSec{
        .mheader{
            height: 70px;
            background-color: #001847;
        }
        .cLogo{
            text-align: center;
            padding: 20px 10px;
            background: #fff;
            max-width: 91%;
            margin: auto;
            border-radius: 6px;
            display: flex;
            align-items: center;
            margin-top: -30px;
            margin-bottom: 30px;
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
            background-image: url('../../assets/images/offer card bg.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            .offerSec{
                width: 100%;
                text-align: right;
                .offerHeading{
                    color: #fff;
                    margin-bottom: 0px;
                    background-color: #FB984A;
                    display: inline-block;
                    padding: 4px 25px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    border-radius: 15px 0 0 0;
                }
                h2{
                    font-size: 0.9rem;
                }
                h1{
                    font-size: 1.2rem;
                    font-weight: 700;
                }

            }
            img{
                width: 100px;
            }
        }
        .geoSearchBox{
            display: flex;
            flex-direction: row;
            input{
                color: rgba(0,0,0,.45);
                font-size: 14px;
                line-height: 1.5715;
                background-color: #fff;
                background-image: none;
                border: 1px solid #d9d9d9;
                border-radius: 4px 0px 0px 4px;
                padding: 6px 10px;
                flex: 1 1 auto;
                width: 1%;
                &:focus,&:focus-visible{
                    box-shadow: none!important;
                    border: 1px solid #d9d9d9;
                }
            }
            button{
                padding-top: 10px;
                padding-bottom: 25px;
                border-radius: 0px 4px 4px 0;
                background-color: @orange-color!important;
                border-color: @orange-color!important;
            }
        }
        .geoSearchButtonBox{
            display: flex;
            column-gap: 10px;
        }
        .merchantsBox{
            background-color: #fff;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
            h4{
                margin-bottom:0px;
                font-size: 0.95rem;
            }
            p{
                margin-bottom:0px;
                font-size: 0.85rem;
                color:#7f8c9c;
                display: flex;
                padding-bottom: 8px;
                span{
                    color: #000;
                    margin-right: 5px;
                    margin-top: 4px;                    
                }
            
            }
            .footerBox{
                display: flex;
                justify-content: space-between;
                font-size: 0.75rem;
                border-top: 1px solid #ddd;
                padding-top: 4px;
                padding-bottom: 0px;
                .direction{
                    color:#425EE2;
                    span.anticon-heat-map{
                        color:#425EE2!important;
                    }
                }
                .radiusText{
                    font-weight: 600;
                    margin-top: 0px;
                    font-size: 0.75rem;
                }
                .view{
                    color:#FB984A;
                    span.anticon-global{
                        color:#FB984A!important;
                    }
                }
            }
        }
    }
    .barcode {
        svg {
            width: 100%;
            text-align: center;
            margin: 0px auto;
        }
    }
    .dialog {
        padding: 2em 0em 2em 0em;
        border: 1px solid #B5CEF9;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        align-self: center;
        justify-content: center;
        span{
            font-size: 4rem;
            color: #faad14;
        }
        .h1 {
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 4px;
            color: #E02840;
            line-height: 1.5em;
            letter-spacing: 0.5px;
            font-family: "OpenSans-Regular", "Open Sans", sans-serif;
        }
        .body {
            color: rgb(53, 38, 38);
            text-align: center;
            line-height: 1.5em;
            font-family: "OpenSans-Regular", "Open Sans", sans-serif;           
        }
    }
}

@hack: true;@import "/Users/nagarajanchandramohan/Desktop/TheeCode/SpacePointe/Ekikart/karstash-react/src/theme/light-theme.less";