.footer-section{
    background: linear-gradient(#fff, #F1F3FD);
    a{
        color: #000!important;
    }
    .borderR{
        border-right: 1px solid #000;
        padding: 0px 3rem;
        .anticon {
                margin-bottom: 0.6rem;
        }
    }
    .social{
        a{
            background: #000;
            display: flex;
            width: 36px;
            text-align: center;
            height: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            color: #fff!important;
            margin-left: 10px;
        }
    }
    .copyright{
        font-size: 0.75rem;
        a{ 
            color:#000!important;
        }
    }
    small {
        font-size: 12px;
    }
}
@hack: true;@import "/Users/nagarajanchandramohan/Desktop/TheeCode/SpacePointe/Ekikart/karstash-react/src/theme/light-theme.less";