.campaignAddBox {
  .templateSelectionCard {
    .heading {
      font-weight: 600;
      margin-bottom: 15px;
      display: block;
      color: @sidebar-menu-dark;
    }
    .templateSelect {
      background-color: @box-gray-color;
      height: 520px;
      overflow-y: auto;
      [type="radio"] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }

      [type="radio"] + img {
        cursor: pointer;
        width: 100%;
        border: 2px dashed @bg-color-light-gray;
        border-radius: @border-radius-box;
        padding: 20px;
        background: #fff;
        margin-bottom: 20px;
        .shadow;
      }

      [type="radio"]:checked + img {
        border: 2px dashed @primary-color-hover;
      }
      [type="radio"] + .customTemplate {
        cursor: pointer;
        width: 100%;
        border-radius: @border-radius-box;
        padding: 20px;
        background: #fff;
        margin-bottom: 20px;
        color: @primary-color;
        text-align: center;
        .shadow;
        p {
          font-weight: 600;
          border: 2px dashed #ddd;
          border-radius: @border-radius-box;
          padding: 30px 0;
          margin-bottom: 0px;
        }
      }

      [type="radio"]:checked + .customTemplate p {
        border: 2px dashed @primary-color-hover;
      }
    }
    .previewSelect {
      justify-content: center;
      img {
        border-radius: 5px;
        padding: 10px;
        .shadow;
      }
    }
    .previewImageBox {
      position: absolute;
      top: 15px;
      right: 10px;
      z-index: 1;
      border: 2px solid #eee;
      padding: 5px 5px 0 5px;
      border-radius: @border-radius-box;
      .shadow-sm;
      .ant-image-mask-info {
        font-size: 0;
      }
      .anticon.anticon-eye {
        font-size: 20px !important;
      }
    }
    .previewTemplate {
      position: relative;
      padding: 18px;
      border-radius: 20px;
      border: 2px solid #ddd;
      margin-bottom: 20px;
      .top {
        background-image: url("../../assets/images/Template/campaign-top.png");
        background-color: #f5f5f5;
        height: 164px;
        width: 300px;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        margin: auto;
        .brand-logo {
          width: 80px;
          padding: 10px;
        }
        h4 {
          color: #fff;
          padding: 10px;
          font-weight: 700;
          margin-left: 18px;
          margin-top: 5px;
        }
        .date {
          position: absolute;
          right: 44px;
          top: 55px;
          text-align: center;
          font-size: 10px;
          h5 {
            margin-bottom: 0px;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
      .products-grid-box {
        background-image: url("../../assets/images/Template/bg.png");
        .products-grid {
          background: #fff;
          border: 3px solid #eee;
          padding: 5px;
          border-radius: 10px;
          text-align: center;
          position: relative;
          .upc-box {
            position: absolute;
            bottom: 0px;
            left: 0px;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 0.44rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-radius: 0px 0px 10px 10px;
            padding: 1px 4px;
          }
          &::before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 7px solid #eee;
            bottom: -8px;
            z-index: 10;
            left: 45%;
          }
          .short-name {
            display: block;
            padding: 0px 0 12px 0;
            font-size: 1.6rem;
            font-weight: 600;
          }
        }
        p {
          margin-top: 5px;
          font-size: 8px;
          text-align: center;
          font-weight: 700;
          .text-ellipse1;
        }
      }
      .bottom {
        background-image: url("../../assets/images/Template/campaign-bottom.png");
        background-color: #f5f5f5;
        height: 104px;
        width: 300px;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        margin: auto;
        top: -15px;
        h4 {
          color: #fff;
          padding: 10px;
          font-weight: 700;
          left: 107px;
          top: 48px;
          position: absolute;
          font-size: 12px;
          -ms-transform: skew(20deg, 4deg);
          transform: skew(20deg, 4deg);
        }
        p {
          color: #fff;
          padding: 10px;
          font-weight: 700;
          left: 11px;
          top: 72px;
          position: absolute;
          font-size: 8px;
        }
      }
    }
    .customTemplateBox {
      background-color: @box-gray-color;
      border-radius: @border-radius-box;
      margin-bottom: 40px;
      color: @primary-color;
      .innerBox {
        background-color: #fff;
        padding: 20px;
        border-radius: @border-radius-box;
        .shadow-sm;
        label {
          justify-content: center;
          display: flex;
          margin: auto;
          cursor: pointer;
          width: 100% !important;
          .ant-upload-select-picture-card {
            background: #fff;
            border: 3px dashed #ddd;
            margin-bottom: 0px;
            border-radius: @border-radius-box;
            height: 300px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .anticon-cloud-upload {
              color: @primary-color;
              font-size: 3.8rem;
              margin-bottom: 6px;
            }
            h5 {
              color: @primary-color;
              font-size: 1rem;
              font-weight: 600;
            }
          }
          img {
            width: 100%;
            margin: auto;
            height: 100%;
            object-fit: contain;
          }

          .ant-upload-list-picture-card-container {
            margin-bottom: 0px;
            height: 300px;
          }

          .ant-upload.ant-upload-select-picture-card > .ant-upload {
            color: rgb(196, 189, 189);
          }
          .ant-upload.ant-upload-select-picture-card,
          .ant-upload-list-picture-card-container {
            width: 100% !important;
          }
        }
        .dragDropText {
          p {
            color: @primary-color;
          }
        }
      }
    }
    .noteBox {
      h4 {
        font-weight: 700;
      }
      ul {
        padding-left: 0px;
        li {
          color: #000;
          list-style-type: none;
          &::before {
            content: "✓";
            padding-right: 10px;
          }
        }
      }
    }
    .uploadCustomTemplateBox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 520px;
      width: 100%;
      .inner {
        padding: 6rem 0;
        text-align: center;
        border-radius: @border-radius-box;
        width: 100%;
        .shadow;
        p {
          color: @heading-light-color;
        }
      }
    }
    .brandTemplateSec {
      background-color: #fff;
      padding: 20px;
      margin: 38px 0 20px 0;
      border-radius: 6px;
      .shadow;
      header{
        img {
          width: 150px;
        }
      } 
      .campaignNameSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        h1 {
          width: 60%; 
          font-size: 1.4rem;
          word-break: break-word;
        }
        span {
          background-color: #43b97f;
          padding: 13px 20px;
          border-radius: 6px;
          align-self: center;
          color: #fff;
        }
      }
      .campaignDateOffSec {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .dateSec {
          display: flex;
          align-self: center;
          div {
            display: flex;
            align-items: center;
            &:first-child {
              padding-right: 25px;
              border-right: 2px solid #ddd;
            }
            &:last-child {
              padding-left: 25px;
            }
            p{
              margin: 0px;
              .dateHeading {
                color: #b4b4b4;
              }
              .dateValue {
                font-size: 1rem;
                color: #434343;
                font-weight: 700;
              }
            }
            .icon {
              background-color: #43b97f;
              padding: 5px;
              margin-right: 15px;
              border-radius: 6px;
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
              .anticon {
                font-size: 2rem;
                padding: 8px;
                color: #fff;
              }
            }
          }
        }
        .offSec {
          padding: 5px;
          background-color: #f40108;
          border-radius: 6px;
          span {
            padding: 0px 25px;
            font-size: 2.55rem;
            font-weight: 700;
            border: 1px dashed #fff;
            border-radius: 6px;
            align-self: center;
            color: #fff;
            width: 100%;
          }
        }
      }
      .campaignStateQRSec {
        display: flex;
        align-self: center;
        column-gap: 30px;
        margin-bottom: 15px;
        h4 {
          margin: 0px;
          font-size: 1.2rem;
          color: #434343;
        }
        p {
          margin: 0px;
        }
        .stateSec {
          border: 2px solid #ddd;
          padding: 15px;
          border-radius: 8px;
          flex: 1 1 auto !important;
          width: 100%; 
          p{
            span {
              font-size: 0.8rem;
              padding: 0 10px;
              border-right: 1px solid #ddd;
              color: #b4b4b4;
              &:first-child {
                padding-left: 0px;
              }
              &:last-child {
                border-right: 0px;
              }
            }
          }
          .inner {
            flex-wrap: wrap !important;
            display: flex;
            span {
              background-color: @primary-color;
              color: #fff;
              padding: 4px 18px;
              margin-right: 4px;
              margin-bottom: 4px;
              border-radius: 20px;
              font-size: 0.75rem;
            }
          }
        }
        .qrSec {
          border: 2px solid #ddd;
          padding: 15px;
          border-radius: 8px;
          flex: 1 1 auto !important;
          word-break: break-word;
          & > div {
            display: flex;
          }
          img {
            margin-right: 10px;
            height: 120px;
            width: 130px;
          }
          p {
            align-self: center;
          }
        }
      }
      .campaignProductSec {
        padding: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        border-radius: 10px;
        display: grid;
        grid-template-columns: repeat(4, 23%);
        gap: 20px;
        .column {
          border: 1px solid #ddd;
          border-radius: 6px;
          text-align: center;
          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
          }
          .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #f4f4f4;
            text-align: center;
            padding: 10px;
            /* height: 66px; */
            p {
              margin: 0px;
              font-size: 0.85rem;
              font-weight: 500;
              // display: -webkit-box;
              // -webkit-line-clamp: 2;
              // -webkit-box-orient: vertical;
              // overflow: hidden;
              // text-overflow: ellipsis;
              span {
                color: #b4b4b4;
              }
            }
          }
        }
      }
    } 
  }
}
