.banner-section {
    .trust-icon{
        position: absolute;
        left: 242px;
        width: 250px;
        bottom: 41px;
    }
    .btn-app-store{
        padding: 1rem 3.6rem;
    }
}
.b-free-section{
    h2 {
        line-height: 3.6rem;
        font-size: 2.6rem;
        font-weight: 600;
        margin-bottom: 2rem !important;
    }
    h5{
      font-weight: 600;
    }
    img{
      border-radius: 18px;
    }
    .border-up,.border-down{
      position: absolute;
      top: 27%;
      width: 100%;
      left: 50%;
    }
    button{
      padding: 1.8rem 2rem;
      border-radius: 50px;
      background: @f-ternary-color!important;
      border-color: @f-ternary-color!important;
      color: #fff;
      font-size: 1rem;
      display: inline-flex;
      align-items: center;
      line-height: 2rem;
      font-weight: 500;
      text-decoration: none;
    }
}
.brand-solution-section{
    h2 {
        line-height: 3.6rem;
        font-size: 2.6rem;
        font-weight: 600;
        margin-bottom: 1rem !important;
    }
    p{
        font-size: 1.2rem;
    }
}

.business-section1 {
    background-color: @f-gray-color;
    padding: 0rem 0!important;
    margin-top: -150px;
    h2 {
      line-height: 3.6rem;
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 2rem !important;
    }
  }
  .digital-section1{
  button{
    padding: 1.8rem 2rem;
    border-radius: 50px;
    background: @f-ternary-color!important;
    border-color: @f-ternary-color!important;
    color: #fff;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    line-height: 2rem;
    font-weight: 500;
    text-decoration: none;
  }
}
  .business-section2 {
    h2 {
      line-height: 3.6rem;
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 2rem !important;
    }
  }
  .demo-section {
    .container {
      background-image: url("../../assets/images/Frontend/background.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 14px;
      padding: 0rem 5rem;
      .left {
        .heading {
          padding-top: 10rem;
          color: @f-secondary-color;
          font-size: 1.4rem;
        }
        h2 {
          line-height: 3.6rem;
          font-size: 2.6rem;
          font-weight: 600;
          margin-bottom: 2rem !important;
        }
        button{
          padding: 1.8rem 2rem;
          border-radius: 50px;
          background: @f-ternary-color!important;
          border-color: @f-ternary-color!important;
          color: #fff;
          font-size: 1rem;
          display: inline-flex;
          align-items: center;
          line-height: 2rem;
          font-weight: 500;
          text-decoration: none;
        }
        .input-group > .form-control,
        .input-group > .form-control:focus,
        .input-group > .form-control:active {
          padding: 1rem 2rem;
          border-radius: 50px 0 0 50px;
          border: 0px;
          box-shadow: none;
        }
        .input-group .input-group-text {
          border-radius: 25px;
          padding: 0px;
          display: flex;
          margin-left: -20px;
          border: 0px;
          z-index: 10;
          button {
            display: flex;
            height: 100%;
            line-height: 2.4rem;
            border-radius: 50px;
            border: 0px;
            padding: 0.4rem 1.42rem;
          }
        }
      }
      .right{
          img{
            position: absolute;
            top: -100px;
          }
      }
    }
  }

  .what-we-got-here-section{
    margin-bottom: 5rem;
    h2 {
        line-height: 3.6rem;
        font-size: 2.6rem;
        font-weight: 600;
    }
    h4{
      font-weight: 600;
    }
    p{
      font-size: 1rem;
    }
}
.curve-top{
  img{
      width: 100%;
      margin-top: -56px;
  }
}
.curve-bottom{
  img{
      width: 100%;
      margin-top: -236px;
  }
}