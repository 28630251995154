#Campaigns{
    @import "../../assets/css/common.less";
    @import '_list';
    @import '_add';
    @import '_header';
    @import '_information';
    @import '_productLocation';
    @import '_template';
    @import '_preview';
    @import '_details';
    @import '_cTemplate';
}
@hack: true;@import "/Users/nagarajanchandramohan/Desktop/TheeCode/SpacePointe/Ekikart/karstash-react/src/theme/light-theme.less";